import type {TemplateSubmissionFlowInfo, TemplateSubmissionFlowReducerState} from '@Components/template-submission-flow/template-submission-flow.types';
import {ImproveTemplatesSortType, SubmissionFlowStep} from '@Components/template-submission-flow/template-submission-flow.types';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
  addToProcessedTemplates,
  doSkipTemplate,
  resetSelectedDesignStyles,
  templateSubmissionFlowExtraReducers,
  updateSelectedPosterData,
  updateUrl,
} from '@Components/template-submission-flow/template-submission-flow-extra-reducer';
import {LoadingStates} from '@Utils/loading.util';

const initialState: TemplateSubmissionFlowReducerState = {
  allDesignStylesInfo: [],
  selectedDesignStyles: {},
  selectedPosterHashedId: '',
  selectedPosterPreviewUrl: '',
  posterColor: '',
  currentStep: SubmissionFlowStep.DESIGN_STYLE,
  nextStep: SubmissionFlowStep.DESIGN_STYLE,
  posterType: '',
  templateName: '',
  templateTags: [],
  designerProfileUri: '',
  isTermsAndConditionsSelected: true,
  isAcknowledgementSelected: true,
  isSubmitting: false,
  templateNameInput: '',
  selectedTemplateNameSuggestionPill: '',
  isImproveTemplatesFlow: false,
  improveTemplatesLoadingState: LoadingStates.NOT_LOADED,
  numTemplatesWithDesignStyles: 0,
  numTotalTemplates: 0,
  showSuccessBanner: false,
  templatesToProcess: [],
  improveTemplatesSortType: ImproveTemplatesSortType.SALES,
  doLoadMoreTemplates: true,
  offset: 0,
  processedTemplates: [],
  isPosterViewFlow: false,
  isPreviewOutdated: false,
};

interface DesignStyleSelectionPayload {
  key: string;
  isSelected: boolean;
}

const templateSubmissionFlowSlice = createSlice({
  name: 'templateSubmissionFlow',
  initialState,
  reducers: {
    setInitialState: (state, action: PayloadAction<TemplateSubmissionFlowInfo>) => {
      state.allDesignStylesInfo = action.payload.allDesignStylesInfo;
      state.selectedPosterHashedId = action.payload.selectedPosterHashedId;
      state.selectedPosterPreviewUrl = action.payload.selectedPosterPreviewUrl;
      state.posterColor = action.payload.posterColor;
      state.posterType = action.payload.posterType;
      state.designerProfileUri = action.payload.designerProfileUri;
      state.isImproveTemplatesFlow = action.payload.isImproveTemplatesFlow;
      state.numTemplatesWithDesignStyles = action.payload.numTemplatesWithDesignStyles;
      state.numTotalTemplates = action.payload.numTotalTemplates;
      state.templateName = action.payload.templateName;
      state.isPosterViewFlow = action.payload.isPosterViewFlow;
      state.isPreviewOutdated = action.payload.isPreviewOutdated;
    },
    setDesignStyleSelectionState: (state, action: PayloadAction<DesignStyleSelectionPayload>) => {
      state.selectedDesignStyles[action.payload.key] = action.payload.isSelected;
    },
    incrementSteps: (state) => {
      state.currentStep += 1;
      state.nextStep += 1;
    },
    decrementSteps: (state) => {
      state.currentStep -= 1;
      state.nextStep -= 1;
    },
    setTemplateName: (state, action: PayloadAction<string>) => {
      state.templateName = action.payload;
    },
    setTemplateTag: (state, action: PayloadAction<string>) => {
      state.templateTags.push(action.payload);
    },
    removeSelectedTemplateTag: (state, action: PayloadAction<string>) => {
      const index = state.templateTags.indexOf(action.payload);
      if (index > -1) {
        state.templateTags.splice(index, 1);
      }
    },
    popTemplateTag: (state) => {
      state.templateTags.pop();
    },
    setTermsAndConditionsSelectionState: (state, action: PayloadAction<boolean>) => {
      state.isTermsAndConditionsSelected = action.payload;
    },
    setAcknowledgementSelectionState: (state, action: PayloadAction<boolean>) => {
      state.isAcknowledgementSelected = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setTemplateNameInput: (state, action: PayloadAction<string>) => {
      state.templateNameInput = action.payload;
    },
    setSelectedTemplateNameSuggestionPill: (state, action: PayloadAction<string>) => {
      state.selectedTemplateNameSuggestionPill = action.payload;
    },
    setImproveTemplatesLoadingState: (state, action: PayloadAction<LoadingStates>) => {
      state.improveTemplatesLoadingState = action.payload;
    },
    showNextTemplateAfterSubmit: (state) => {
      resetSelectedDesignStyles(state);
      updateSelectedPosterData(state);
      updateUrl(state.selectedPosterHashedId, state.improveTemplatesSortType);
      state.improveTemplatesLoadingState = LoadingStates.LOADED;
      state.showSuccessBanner = false;
    },
    showNextTemplateAfterSkip: (state) => {
      if (state.templatesToProcess.length === 0) {
        return;
      }
      addToProcessedTemplates(state);
      doSkipTemplate(state);
    },
    markPreviewAsUpdated: (state) => {
      state.isPreviewOutdated = false;
    },
  },
  extraReducers: templateSubmissionFlowExtraReducers,
});

export const {
  setInitialState,
  setDesignStyleSelectionState,
  setTemplateName,
  setTemplateTag,
  removeSelectedTemplateTag,
  popTemplateTag,
  incrementSteps,
  decrementSteps,
  setTermsAndConditionsSelectionState,
  setAcknowledgementSelectionState,
  setTemplateNameInput,
  setSelectedTemplateNameSuggestionPill,
  showNextTemplateAfterSubmit,
  showNextTemplateAfterSkip,
  markPreviewAsUpdated,
} = templateSubmissionFlowSlice.actions;
export const templateSubmissionFlowReducer = templateSubmissionFlowSlice.reducer;
