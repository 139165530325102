import type {Emitter} from 'mitt';
import mitt from 'mitt';
import type {UserMediaType, UserMediaVOResponse} from '@Libraries/user-media-library';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Event = {
  'modal:open': {id: string; props: Record<string, unknown>};
  'modal:close': string;
  'user:loggedIn': undefined;
  'user:loggedOut': undefined;
  'userPreferences:areAlignmentGuidesEnabledUpdated': undefined;
  'font:uploadStart': {fontsProcessed: number; totalNumberOfFonts: number};
  'font:uploadEnd': undefined;
  'brand:logo:uploadStart': undefined;
  'brand:logo:uploadFail': undefined;
  'brand:logo:uploadSuccess': undefined;
  'userMedia:upload:start': {type: UserMediaType; file: File; collectionId: string; mirror?: boolean};
  'userMedia:upload:fail': {type: UserMediaType; file: File; collectionId: string};
  'userMedia:upload:success': {type: UserMediaType; file: File; collectionId: string; data: UserMediaVOResponse};
};

const pmwEventEmitter = ((): {getInstance(): Emitter<Event>} => {
  const instance: Emitter<Event> = mitt<Event>();
  return {
    getInstance(): Emitter<Event> {
      return instance;
    },
  };
})();

export default pmwEventEmitter.getInstance();
