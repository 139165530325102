import './input-field.scss';
import {Icon} from '@Components/icon-v2';
import type {Ref} from 'react';
import React, {useEffect, useState} from 'react';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import styles from './input-field.module.scss';
import {noop} from '@Utils/general.util';

export interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id?: string;
  inputClassName?: string;
  containerClassName?: string;
  maxLength?: number;
  isMultiLine?: boolean;
  multiLineRows?: number;
  name?: string;
  onInputBlur?: Function;
  onInputChange?: Function;
  onInputFocus?: Function;
  onInputSubmit?: Function;
  placeHolder?: string;
  submitOnEnter?: boolean;
  type?: INPUT_FIELD_TYPE;
  label?: JSX.Element;
  value?: string | number;
  showInputIcon?: boolean;
  inputIconName?: string;
  inputIconExtraClass?: string;
  inputIconSize?: IconSize;
  inputIconType?: IconType;
  inputIconShape?: IconShape;
  onInputIconClick?: Function;
  selectTextOnFocus?: boolean;
  handleSpecificBlur?: boolean;
  disabled?: boolean;
  input?: string;
}

export enum INPUT_FIELD_TYPE {
  TEXT = 'text',
  HIDDEN = 'hidden',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
}

export const InputField = React.forwardRef(
  (
    {
      type = INPUT_FIELD_TYPE.TEXT,
      maxLength = 200,
      onInputBlur = $.noop,
      onInputFocus = $.noop,
      onInputChange = $.noop,
      onInputSubmit = $.noop,
      submitOnEnter = true,
      value = '',
      name = '',
      inputClassName = '',
      containerClassName = '',
      showInputIcon = true,
      isMultiLine = false,
      multiLineRows = 2,
      selectTextOnFocus = false,
      inputIconExtraClass = '',
      inputIconSize = IconSize.SIZE_ICON_16,
      onInputIconClick = $.noop,
      inputIconName = '',
      handleSpecificBlur = false,
      inputIconType = IconType.TRANSPARENT,
      inputIconShape = IconShape.SQUARE,
      disabled = false,
      ...props
    }: InputFieldProps,
    ref: Ref<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const getInitialIconForInputType = () => {
      if (inputIconName !== '') {
        return inputIconName;
      }

      if (type === INPUT_FIELD_TYPE.PASSWORD) {
        return 'icon-eye-off';
      }

      return 'icon-close';
    };

    const getInitialPlaceHolderForInputType = () => {
      if (props.placeholder !== undefined) {
        return props.placeholder;
      }

      switch (type) {
        case INPUT_FIELD_TYPE.NUMBER:
          return window.i18next.t('pmwjs_enter_number');
        case INPUT_FIELD_TYPE.PASSWORD:
          return window.i18next.t('pmwjs_password');
        case INPUT_FIELD_TYPE.EMAIL:
          return window.i18next.t('pmwjs_email_address');
        case INPUT_FIELD_TYPE.TEXT:
          return window.i18next.t('pmwjs_add_text');
        default:
          return '';
      }
    };

    const [inputText, setInputText] = useState(value);
    const [placeholder, setPlaceHolder] = useState(getInitialPlaceHolderForInputType());
    const [icon, setIcon] = useState(getInitialIconForInputType());

    const handleInputChange = (val: string) => {
      let valueToSet = val;

      if (props.max !== undefined && val > props.max) {
        valueToSet = props.max.toString();
      } else if (props.min !== undefined && val < props.min) {
        valueToSet = props.min.toString();
      }

      setInputText(valueToSet);
      onInputChange(valueToSet);
    };

    const isPasswordShown = () => {
      return icon === 'icon-eye';
    };

    const getType = () => {
      if (type === INPUT_FIELD_TYPE.PASSWORD && isPasswordShown()) {
        return INPUT_FIELD_TYPE.TEXT;
      }

      return type;
    };

    const onButtonClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onInputIconClick !== $.noop && onInputIconClick !== noop) {
        onInputIconClick(e);
      } else if (icon === 'icon-close') {
        handleInputChange('');
      } else if (icon === 'icon-eye') {
        setIcon('icon-eye-off');
      } else if (icon === 'icon-eye-off') {
        setIcon('icon-eye');
      }
    };

    useEffect(() => {
      setInputText(value);
    }, [value]);

    useEffect(() => {
      setIcon(getInitialIconForInputType());
    }, [type]);

    useEffect(() => {
      setIcon(getInitialIconForInputType());
    }, [inputIconName]);

    useEffect(() => {
      setPlaceHolder(getInitialPlaceHolderForInputType());
    }, [type]);

    /**
     * Forward all passed props to input and add some props to optimize the type of input
     */
    const getOtherInputProps = () => {
      let inputProps = {};
      if (type === INPUT_FIELD_TYPE.EMAIL) {
        inputProps = {
          ...inputProps,
          autoCapitalize: 'off',
          autoCorrect: 'off',
          autoComplete: 'email',
        };
      }

      inputProps = {
        ...inputProps,
        ...props,
        ref,
      };

      if (isMultiLine) {
        inputProps = {
          ...inputProps,
          rows: multiLineRows,
        };
      }

      return inputProps;
    };

    const showIcon = () => {
      if (!showInputIcon || !icon) {
        return false;
      }

      return !(icon == 'icon-close' && !inputText);
    };

    const getIcon = () => {
      if (showIcon()) {
        return (
          <div onClick={onButtonClick}>
            <Icon icon={icon} size={inputIconSize} className={`${inputIconExtraClass} ${styles.icon} `} type={inputIconType} shape={inputIconShape} />
          </div>
        );
      }

      return null;
    };

    const selectInputText = (target: HTMLInputElement) => {
      if (selectTextOnFocus) {
        target.select();
      }
    };

    const onInputElFocus = (target: HTMLInputElement) => {
      selectInputText(target);
      onInputFocus(target.value);
    };

    const RootEl = isMultiLine ? 'textarea' : 'input';

    return (
      <div className={`${styles.container} ${containerClassName}`}>
        {props.label}
        <div id={props.id} className={`input-field ${props.className}${type === INPUT_FIELD_TYPE.HIDDEN ? ' _hidden' : ''}`}>
          <RootEl
            {...getOtherInputProps()}
            maxLength={maxLength}
            type={getType()}
            name={name}
            value={inputText}
            className={`input-field-txt ${inputClassName}`}
            onBlur={(e) => {
              if (handleSpecificBlur) {
                return onInputBlur(e);
              }
              return onInputBlur(e.target.value);
            }}
            onFocus={(e) => {
              return onInputElFocus(e.target as HTMLInputElement);
            }}
            onPointerUp={(e) => {
              return selectInputText(e.target as HTMLInputElement);
            }}
            onChange={(e) => {
              return handleInputChange(e.target.value);
            }}
            onKeyUp={(e) => {
              if (submitOnEnter && e.key === 'Enter') {
                onInputSubmit();
              }

              if (e.key === 'Escape' && document.activeElement) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                document.activeElement.blur();
              }
            }}
            aria-placeholder={placeholder}
            disabled={disabled}
          />
          {getIcon()}
        </div>
      </div>
    );
  }
);
