import type {ReactElement} from 'react';
import type {FlatIconType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {SkeletonBrickTheme} from '@Components/skeleton-brick';

export interface ThumbnailProps {
  className?: string;

  onClick?(): void;

  data: ThumbnailData;
}

export type ThumbnailData = IconThumbnailData | ImageThumbnailData | CustomThumbnailData;

export interface ThumbnailBaseData {
  type: ThumbnailType;
}

export interface IconThumbnailData extends ThumbnailBaseData {
  disabled?: boolean;
  flat_icon_type?: FlatIconType;
  icon: string;
  IconShape?: IconShape;
  icon_size?: IconSize;
  icon_type?: IconType;
  type: ThumbnailType.ICON;
}

export interface ImageThumbnailData extends ThumbnailBaseData {
  type: ThumbnailType.IMAGE;
  imageSrc: string;
  disabled?: boolean;
  showSkeletonLoading?: boolean;
  skeletonLoadingTheme?: SkeletonBrickTheme;
}

export interface CustomThumbnailData {
  type: ThumbnailType.CUSTOM;
  content: ReactElement;
  className?: string;
}

export enum ThumbnailType {
  ICON = 'icon',
  IMAGE = 'image',
  CUSTOM = 'custom',
}
