import type {ReactElement} from 'react';
import React from 'react';
import styles from './audio-item-wave.module.scss';

const FILL_WITH_BARS_PERCENTAGE = 50;
const TOTAL_BARS = 7;

export interface AudioItemWaveProps {
  isAnimating: boolean;
  width: number;
  height: number;
  color: string;
  totalBars?: number;
  className?: string;
}

export default function AudioItemWave({isAnimating, ...props}: AudioItemWaveProps): ReactElement {
  const totalBars = props.totalBars ?? TOTAL_BARS;
  const waveBarWidth = Math.floor((props.width * FILL_WITH_BARS_PERCENTAGE) / 100 / totalBars);

  const bars = Array.from({length: totalBars}, (_, index) => {
    const isSmallBar = index % 3 === 0;
    const heightPercentage = isSmallBar ? 50 : 100;
    const animationClass = isSmallBar ? styles.smallBar : styles.bigBar;

    return (
      <div
        key={index}
        className={`${styles.waveBar} ${isAnimating ? animationClass : ''}`}
        style={{
          height: `${heightPercentage}%`,
          width: `${waveBarWidth}px`,
          backgroundColor: props.color,
        }}
      />
    );
  });

  return (
    <div
      style={{height: `${props.height}px`, width: `${props.width}px`}}
      className={`${styles.audioWaveContainer} flex-justify-between flex-h-row flex-items-center ${props.className ?? ''}`}
    >
      {bars}
    </div>
  );
}
