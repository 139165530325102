import type {SizeUnits} from '@PosterWhiteboard/libraries/resize-poster.library';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';

export interface ResizeData {
  isCustomDimension: boolean;
  posterType?: PosterTypeObject;
}

export interface CopyAndResizeCTAPropItem extends ResizeData {
  isRotated: string;
}

export const enum Orientation {
  LANDSCAPE = 'Landscape',
  PORTRAIT = 'Portrait',
}

export type CopyAndResizeData = Array<CopyAndResizeCTAPropItem>;

export interface SubItemsData {
  id: string;
  text: Orientation;
  isSelected: boolean;
}

export interface UnsavedCustomDimension {
  width: number;
  height: number;
  units: SizeUnits;
}

export interface ResizeModalPosterInformation {
  posterType?: string;
  posterDimensionText: string;
}

export const POSTER_CUSTOM_PRINT = 'poster-custom-print';
export const POSTER_CUSTOM = 'poster-custom';
